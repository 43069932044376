import React, { useEffect, useState } from 'react';

const LeaderboardList = ({ raceData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    if (raceData?.length > 0) {
      // Initialize displayData with the first set of data
      setDisplayData(raceData[0]);
      setCurrentIndex(0);

      // Set up an interval to update the data every second
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex < raceData.length - 1) {
            const nextIndex = prevIndex + 1;
            setDisplayData(raceData[nextIndex]);
            return nextIndex;
          } else {
            // We've reached the end, clear the interval
            clearInterval(interval);
            return prevIndex; // Keep the index at the last position
          }
        });
      }, 1050);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [raceData]);

  return (
    <>
      <div className="leaderboard-title">Leaderboard</div>
      <div className='leaderboard-details-sec'>
        <ul className="leaderboard-list">
          {Array.isArray(displayData) && displayData.map((item, indexLeaderBoard) => (
            <li key={item.assetId} className="leaderboard-item" style={{ top: `${indexLeaderBoard * 30}px` }}>
              <div className="leaderboard-rank">
                <span className='rank-circle' style={{ background: item.colorCode }}></span>
                <span>{indexLeaderBoard + 1}</span>
              </div>
              <div className="leaderboard-name" style={{ color: item.colorCode }}>
                {item.sNFTName.length > 8
                  ? `${item.sNFTName.substring(0, 8)}...`
                  : item.sNFTName}
              </div>
              <div className="leaderboard-name" style={{ color: item.colorCode }}>{item.sOwnerName}</div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LeaderboardList;