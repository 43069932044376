import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Table, Dropdown, Form } from "react-bootstrap";
import { FormGroup } from "reactstrap";

const ScheduleRaceList = ({ roundNFTs, sendMessageToPhaser, raceTimer, raceScheduleLoading, socket }) => {
  const [remainingTimes, setRemainingTimes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const tableContainerRef = useRef(null);
  const [filteredRounds, setFilterRounds] = useState([])

  // // Filter rounds based on search term
  // const filteredRounds = roundNFTs?.filter((round) =>
  //   round?.aNFT?.some((item) =>
  //     item.some((name) =>
  //       name.sNFTName.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   )
  // );

  const handleBuyNow = useCallback((url) => window.open(url, '_blank', 'noopener,noreferrer'), [])
  const handleReplayBtn = useCallback((nRaceNumber, nRoundNumber) => sendMessageToPhaser(nRaceNumber, nRoundNumber), [sendMessageToPhaser])

  useEffect(() => {
    if (searchTerm !== '') {
      socket.emit('reqSearchNFT', { sNFTName: searchTerm }, (error, data) => {
        const searchData = data?.oData?.data;

        // Filtering roundNFTs by comparing sRaceName
        setFilterRounds(roundNFTs?.filter((round) =>
          round.sRaceName && searchData?.some((searchItem) =>
            searchItem.sRaceName?.toLowerCase() === round.sRaceName.toLowerCase()
          )
        ))
      })
    } else {
      console.log('here')
      setFilterRounds(roundNFTs)
    }
  }, [roundNFTs, searchTerm, socket])

  useEffect(() => {
    const nStartTimes = searchTerm === '' ? raceTimer?.map((round) => round.nRaceTimer) : filteredRounds?.map((round) => round.nRaceTimer - 3000);
    if (nStartTimes && nStartTimes.length > 0) {
      setRemainingTimes(nStartTimes);
    }
  }, [filteredRounds, raceTimer, searchTerm]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTimes((prevTimes) => {
        return prevTimes.map((time) => {
          if (time <= 0) {
            return 0;
          }
          return time - 1000;
        });
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [roundNFTs, searchTerm, filteredRounds]);

  useEffect(() => {
    const playingRowIndex = roundNFTs?.findIndex(
      (round) => round?.sRaceStatus === 'Playing'
    );
    if (playingRowIndex !== -1 && tableContainerRef.current) {
      const rows = tableContainerRef.current.querySelectorAll('tbody tr');
      if (rows[playingRowIndex]) {
        const rowHeight = rows[playingRowIndex].offsetHeight;
        const containerHeight = tableContainerRef.current.clientHeight;

        // Scroll to make the "Playing" row in the middle of the container
        const scrollPosition = rows[playingRowIndex].offsetTop - (containerHeight / 2) + (rowHeight / 2);

        tableContainerRef.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    }

    setFilterRounds(roundNFTs)
  }, [roundNFTs]);

  const padZero = useCallback((number) => number.toString().padStart(2, '0'), [])
  const formatTime = useCallback((time) => {
    if (time === null) {
      return '00:00:00';
    }
    if (time === 0) {
      return '00:00:00';
    }
    let seconds = Math.floor((time / 1000) % 60);
    let minutes = Math.floor((time / 1000 / 60) % 60);
    let hours = Math.floor((time / 1000 / 60 / 60) % 24);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }, [padZero])

  return (
    <>
      <div className='search-schedule text-light'>
        <FormGroup className="form-group position-relative">
          <Form.Control
            type="text"
            placeholder="( Search NFT Name )"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          {searchTerm && (
            <button
              type="button"
              className="clear-button"
              onClick={() => { setSearchTerm('') }}
              style={{
                position: 'absolute',
                right: '10px',
                top: '30%',
                transform: 'translateY(-50%)',
                background: 'transparent',
                border: 'none',
                fontSize: '28px',
                cursor: 'pointer',
                color: '#fff',
              }}
            >
              &times;
            </button>
          )}
        </FormGroup>
      </div >
      <div className='schedule-race' ref={tableContainerRef} style={{ maxHeight: '400px', overflowY: 'auto', position: "relative", scrollBehavior: "smooth", }}>
        <Table responsive borderless>
          <thead>
            <tr>
              <th className="bg-transparent">Race Id</th>
              <th className="bg-transparent">Eliminated</th>
              <th className="bg-transparent">RACERS</th>
              <th className="bg-transparent">Start Time</th>
            </tr>
          </thead>
          <tbody>
            {raceScheduleLoading ? <tr><td colSpan={4} className="text-center">Loading...</td></tr>
              : filteredRounds?.length > 0 ? filteredRounds?.map((round, index) => {
                const remainingTime = remainingTimes[index] || 0;
                let roundNo = round.sRaceName.split("r")[1];
                let raceNo = round.sRaceName.split("r")[2];
                return (
                  <tr key={index}>
                    <td>{round.sRaceName}</td>
                    <td>{round?.oLostNFT ? round.oLostNFT.sNFTName : '-'} </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          View List
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {round?.aNFT?.map((item, i) => (
                            <React.Fragment key={i}>
                              {item?.map((name) => (
                                <Dropdown.Item
                                  key={name?.sNFTName}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <span value={item.assetId}>{name?.sNFTName}</span>
                                  <button value={item.assetId} onClick={() => handleBuyNow(name?.sURL)}>Buy Now</button>
                                </Dropdown.Item>
                              ))}
                            </React.Fragment>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      {round?.sRaceStatus === 'Playing' ? 'LIVE'
                        : (round?.isLuckyRound && round.sRaceStatus === 'Scheduled') ? 'Lucky Winner' :
                          (round?.isLuckyRound && round.sRaceStatus === 'Finished') ? <button onClick={() => sendMessageToPhaser('Lucky Winner', round.aNFT)} className="replay-btn" > Replay </button>
                            : round.sRaceStatus === 'Scheduled' ? (<span>{formatTime(remainingTime)}</span>)
                              : round.sRaceStatus === 'Finished' &&
                              (<button onClick={() => handleReplayBtn(raceNo, roundNo)} className="replay-btn" > Replay </button>)
                      }
                    </td>
                  </tr>
                );
              }) : <tr><td colSpan={4} className="text-center">No Data Found</td></tr>}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ScheduleRaceList;
